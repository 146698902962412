import React from 'react';
import Markdown, { Components } from 'react-markdown';
import classNames from 'classnames';
import Image from 'antd/lib/image';
import remarkGfm from 'remark-gfm';
import remarkHtml from 'remark-html';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
import remarkDirective from 'remark-directive';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import typescript from 'react-syntax-highlighter/dist/cjs/languages/prism/typescript';
import json from 'react-syntax-highlighter/dist/cjs/languages/prism/json';
import javascript from 'react-syntax-highlighter/dist/cjs/languages/prism/javascript';
import { oneDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import rangeParser from 'parse-numeric-range';
import { visit } from 'unist-util-visit';
import styles from './MarkdownViewer.module.css';
import globals from '@common/globals';
import useTranslation from '@hooks/useTranslation';
SyntaxHighlighter.registerLanguage('typescript', typescript);
SyntaxHighlighter.registerLanguage('json', json);
SyntaxHighlighter.registerLanguage('javascript', javascript);

type Props = {
  markdown: string;
  docsUrl: string;
}

// Create a custom schema based on the default that allows style attributes and video elements
const customSchema = {
  ...defaultSchema,
  attributes: {
    ...defaultSchema.attributes,
    // Add 'style' to the list of allowed attributes for all elements
    '*': [...(defaultSchema.attributes?.['*'] || []), 'style', 'className'],
    // Add specific attributes needed for video elements
    video: [
      ...(defaultSchema.attributes?.video || []),
      'src', 'controls', 'autoplay', 'muted', 'loop', 'poster', 'preload', 'width', 'height',
      'playsinline', 'webkit-playsinline'
    ],
    // Add attributes for source elements inside video
    source: [
      ...(defaultSchema.attributes?.source || []),
      'src', 'type'
    ],
    img: [
      ...(defaultSchema.attributes?.img || []),
      'src', 'alt', 'width', 'height', 'style', 'className'
    ]
  },
  tagNames: [
    ...(defaultSchema.tagNames || []),
    'div', // Ensure div is allowed for our custom blocks
    'video', // Allow video elements
    'source', // Allow source elements inside video
    'track' // Allow track elements for captions
  ]
};

/**
 * Plugin to transform directives into div elements with specific classes
 * 
 * Processes an 'aligned-block' directive, which is used to set the width and alignment of a block.
 * Usage:
 * ```
 * :::aligned-block{.60.center}
 * This content will be 60% width and centered.
 * :::
 * ```
 */
const remarkCustomBlocks = () => {
  return (tree: any) => {
    visit(tree, (node) => {
      if (
        (node.type === 'containerDirective' ||
         node.type === 'textDirective' ||
         node.type === 'leafDirective') &&
        node.name === 'aligned-block'
      ) {
        // Parse the parameters from node.attributes.className
        let width = null;
        let alignment = null;

        if (node.attributes && node.attributes.class) {
          const parts = node.attributes.class.split(' ');

          // Extract width (if provided)
          if (parts[0] && ['50', '60', '70', '80'].includes(parts[0])) {
            width = parts[0];
          }

          // Extract alignment (if provided)
          if (parts[1] && ['left', 'center', 'right'].includes(parts[1])) {
            alignment = parts[1];
          }
        }

        // Create a string of CSS module class names using classNames
        const blockClass = classNames(
          styles.CustomBlock,
          {
            [styles.width50]: width === '40',
            [styles.width50]: width === '50',
            [styles.width60]: width === '60',
            [styles.width70]: width === '70',
            [styles.width80]: width === '80',
            [styles.width90]: width === '90',
            [styles.width100]: width === '100',
            [styles.alignLeft]: alignment === 'left',
            [styles.alignCenter]: alignment === 'center',
            [styles.alignRight]: alignment === 'right'
          }
        );

        // Convert node to div with our classes
        node.data = {
          ...(node.data || {}),
          hName: 'div',
          hProperties: {
            className: blockClass
          }
        };
      }
    });
  };
};

const MarkdownViewer = (mainProps: Props) => {
  const t = useTranslation('MarkdownViewer');
  const syntaxTheme = oneDark;
  const MarkdownComponents: Components = {
    code ({ node, className, ...props }) {
      const hasLang = /language-(\w+)/.exec(className || '');
      const hasMeta = node?.data?.meta;

      // Line numbers based on: https://amirardalan.com/blog/syntax-highlight-code-in-markdown
      const applyHighlights: object = (applyHighlights: number) => {
        if (hasMeta) {
          const RE = /{([\d,-]+)}/;
          const metadata = node?.data?.meta?.replace(/\s/g, '');
          if (!metadata) { return {}; }

          const strlineNumbers = RE?.test(metadata)
            ? RE?.exec(metadata)?.[1]
            : '0';
          const highlightLines = rangeParser(strlineNumbers as string);
          const highlight = highlightLines;
          const data = highlight.includes(applyHighlights)
            ? 'highlight'
            : null;
          return { data };
        } else {
          return {};
        }
      };

      return hasLang ? (
        // @ts-expect-error ignore
        <SyntaxHighlighter
          style={syntaxTheme}
          language={hasLang[1]}
          PreTag="div"
          className="codeStyle"
          showLineNumbers={true}
          wrapLines={hasMeta}
          useInlineStyles={true}
          lineProps={applyHighlights}
        >
          {props.children}
        </SyntaxHighlighter>
      ) : (
        <code className={className} {...props} />
      );
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    table ({ node, ...props }) {
      return <div className={styles.WrapperTable}><table className={styles.Table} {...props} /></div>;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    video ({ node, ...props }) {
      // Check if the image URL starts with /docs/ and prefix it
      const videoSrc = props.src?.startsWith('/docs/')
        ? `${globals.MARKETPLACE_PUBLIC_URL}/${mainProps.docsUrl}/${props.src.replace('/docs/', '')}`
        : props.src;

      return <video className={styles.Video} controls {...props} src={videoSrc}>
        {props.children}
      </video>;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    source ({ node, ...props }) {
      const src = props.src?.startsWith('/docs/')
        ? `${globals.MARKETPLACE_PUBLIC_URL}/${mainProps.docsUrl}/${props.src.replace('/docs/', '')}`
        : props.src;

      return <source {...props} src={src} />;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    img ({ node, ...props }) {
      const src = props.src || '';

      // Check if the image URL starts with /docs/ and prefix it
      const imageSrc = src.startsWith('/docs/')
        ? `${globals.MARKETPLACE_PUBLIC_URL}/${mainProps.docsUrl}/${src.replace('/docs/', '')}`
        : src;

      // return <img  {...props} src={imageSrc} alt={props.alt || ''} />;
      return <Image
        preview={{
          mask: t('ClickToEnlarge')
        }}
        width={props.width}
        height={props.height}
        src={imageSrc}
        alt={props.alt || ''}
        className={styles.PreviewImage}
      />;
    }
  };

  return (
    <Markdown
      remarkPlugins={[
        remarkGfm,
        remarkHtml,
        remarkDirective,
        remarkCustomBlocks
      ]}
      rehypePlugins={[
        rehypeRaw,
        [rehypeSanitize, customSchema]
      ]}
      components={MarkdownComponents}
    >
      {mainProps.markdown}
    </Markdown>
  );
};

export default MarkdownViewer;
